.group {
    zoom: 1;

    &:after,
    &:before {
        content: "";
        display: table
    }

    &:after {
        clear: both
    }
}
body {
    font-family: myriad-pro-n4, myriad-pro, verdana, sans-serif;
    font-size: 84%;
    font-weight: 400;
    font-style: normal;
    color: #000;
    background-color: #f5f5f5;
}
.placeholder {
    color: #575757!important
}
::-webkit-input-placeholder {
    color: #575757
}
:-moz-placeholder {
    color: #575757
}
::-moz-placeholder {
    color: #575757
}
:-ms-input-placeholder {
    color: #575757
}
.reg {
    font-family: verdana!important
}
.container {
    margin: 20px auto;
    width: 960px;
    background-color: #fff;
    border-left: 1px solid #232125;
    border-right: 1px solid #232125;
    border-bottom: 1px solid #232125
}
.main {
    background-color: #dbe9f6
}
.wrapper {
    margin: auto;
    width: 886px
}
.header-bar {
    color: #fff;
    background-color: #1a3866;

    .options {
        float: right;
        width: 276px;
        padding: 10px 0 5px 14px;
        text-align: right
    }

    .pre {
        background-color: #607595;
        text-align: left
    }

    a:link,
    a:visited {
        position: relative;
        color: #d4135a;
        background-color: #fff;
        padding: 2px 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        behavior: url(/PIE.htc)
    }
    .accessibility:hover,
    a:hover {
        cursor: pointer
    }
    .accessibility-box {
        display: inline-block;
        margin: 0 10px 0 0
    }
    .accessibility.normal {
        font-size: 12px
    }
    .accessibility.large {
        font-size: 14px
    }
    .accessibility-title {
        display: inline-block;
        margin: 0 0 0 10px
    }
    .accessibility.huge {
        font-size: 16px
    }
    .login-title {
        text-transform: uppercase;
        font-size: 1.12em
    }
}

.banner {
    height: 99px
}
.pre-curve {
    background: url(/images/default/pre-curve.png) top right no-repeat
}
.post-curve {
    background: url(/images/default/post-curve.png) top right no-repeat
}
.logo {
    margin: 10px 0 0;
    width: 580px;
    height: 78px;
    float: left
}
.print-logo {
    display: none
}
.login {
    width: 290px;
    float: right
}
.login .ticket-form {
    margin: 15px 0 0;
    padding: 15px 0 14px
}
.credentials-form {
    padding: 25px 0 14px
}
.login .form-helper {
    display: block;
    padding: 0 0 5px 14px;
    color: #1a3866;
    font-family: myriad-pro-n9,myriad-pro,sans-serif;
    font-style: normal
}
.login label {
    display: block;
    float: left;
    padding: 5px 10px 0 14px;
    color: #1a3866
}
.login .password-label {
    padding: 5px 14px 0
}
.login .credentials-form label {
    padding-top: 0
}
.login #password,
.login #username {
    float: left;
    width: 165px;
    margin: 0 0 5px;
    padding: 1px;
    border: 0
}
.login #password {
    font-family: sans-serif
}
.login #ticket {
    float: left;
    width: 160px;
    padding: 5px;
    border: 0
}
.login input[type=submit] {
    float: left;
    width: 25px;
    margin: 0 0 0 5px;
    padding: 5px;
    border: 0;
    text-indent: -9999px;
    text-transform: capitalize;
    background: url(/images/default/arrow-orange.png) top right no-repeat
}
.login .errors {
    position: absolute;
    bottom: -200;
    left: -100;
    z-index: 100;
    padding: 0;
    width: 250px;
    list-style: none
}
.login .errors li {
    padding: 10px;
    text-align: center;
    background-color: #b94a48;
    color: #fff
}
dd,
dt {
    width: 0;
    height: 0
}
.search-bar {
    padding: 10px 10px 10px 24px;
    color: #fff;
    background-color: #1a3866
}
.search-bar .date {
    padding: 5px 0;
    float: left;
    font-size: .9em
}
.search-bar .search {
    padding: 0;
    float: right
}
.search-bar form input {
    position: relative;
    border: none;
    font-size: 1em;
    float: left;
    padding: 0 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    behavior: url(/PIE.htc)
}
.search-bar form input:not(:focus){
    outline: 0;
}
.search-bar form input#term {
    width: 245px;
    height: 27px;
    color: #575757;
    background: #fff;
}
.search-bar form input#submit {
    position: relative;
    display: block;
    width: 27px;
    height: 27px;
    padding: 0;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 700;
    background-color: #008575;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 30px;
    color: #fff;
    text-align: center
}
.content th,
.store-options {
    text-align: left
}
.search-bar dl {
    display: block;
    margin: 0;
    padding: 0
}
.ui-autocomplete {
    width: 240px!important;
    border-radius: 0!important;
    border: 1px solid #f4f8fc!important
}
.ui-menu-item a {
    padding: 0 17px!important;
    font-size: 13px!important;
    border-radius: 0
}
.ui-menu .ui-menu-item a.ui-state-focus {
    background: #ebebeb!important;
    border: 0;
    margin: 0!important
}
.nav {
    width: 214px;
    float: left
}
.admin-menu {
    position: relative;
    margin: 38px 0 0;
    padding: 0;
    list-style: none;
    border-top: 2px solid #fff
}
.admin-menu a {
    display: block;
    padding: 11px 0 11px 24px;
    color: #1A3866;
    font-size: 1em
}
.admin-menu a:link,
.admin-menu a:visited {
    text-decoration: none
}
.admin-menu a:hover {
    text-decoration: underline;
    background-color: #ccdff3
}
.admin-menu li {
    border-bottom: 2px solid #fff
}
.admin-menu li:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 15px 5px;
    background-color: #f58032
}
.admin-menu li.WN:before {
    background-color: #b1242c
}
.admin-menu li.BOP:before {
    background-color: #2e60a5
}
.admin-menu li.BIF:before {
    background-color: #390
}
.admin-menu li.MBP:before,
.admin-menu li.SEC:before,
.admin-menu li.LEG:before {
    background-color: #2e60a5
}
.admin-menu li.ISG:before,
.admin-menu li.SYN:before,
.admin-menu li.LAP:before,
.admin-menu li.EVENT:before{
    background-color: #8652a1
}
.admin-menu li.SRG:before, .admin-menu li.SOU:before {
    background-color: #390
}
.admin-menu li.help:before {
    background-color: #b1242c
}
.admin-menu li.BYCS:before {
    background-color: #2e60a5
}
.admin-menu li.HTG:before {
    background-color: #8652a1
}
.admin-menu li.SAMS:before {
    background-color: #390
}
.BIF li.BIF,
.BOP li.BOP,
.EVENT li.EVENT,
.ISG li.ISG,
.LAP li.LAP,
.LEG li.LEG,
.SOU li.SOU,
.SYN li.SYN,
.whats-new li.WN {
    background-color: #ccdff3;
}
li.WN > a {
    color: #b1242c;
}
.corona-red {
    color: #b1242c !important;
}
.content {
    width: 672px;
    min-height: 465px;
    float: left;
    background-color: #fff;
    word-wrap: break-word;

    img {
        max-width: 100%;
    }

    a:link,
    a:visited {
        color: #d4145a;
        text-decoration: none
    }
    a:hover {
        color: #d4145a;
        text-decoration: underline;
        cursor: pointer;
    }
    p.confirm,
    p.error {
        padding: 10px;
        margin: 35px 0 0 35px;
        color: #fff
    }
    p.error {
        background-color: #b94a48
    }
    p.confirm {
        background-color: #468847
    }

}
.content h1,
h2,
h3 {
    color: #232323
}
.content h1 {
    margin: 0 0 20px;
    font-size: 1.5em
}
.content h2 {
    margin: 20px 0 15px;
    font-size: 1.3em
}
.content h3 {
    margin: 20px 0 0
}
.content h4 {
    margin: 20px 0 0
}
.content h3,
.content li,
.content p,
.content td {
    font-size: 1.05em;
    line-height: 1.4em
}
.content li li,
.content li p {
    font-size: 1em
}
.breadcrumbs,
.doc-status {
    font-size: .8em
}
.content p {
    margin: 0 0 15px
}
.content li {
    margin: 0 0 10px
}
.content table:not(.cw-table) {
    width: 100%;
    border-collapse: collapse
}
.content table:not(.cw-table) td {
    padding: 10px
}
.content table:not(.cw-table).category-list td {
    padding: 10px 10px 10px 0
}
.content-id {
    width: 80px;
    color: #495050;
    vertical-align: top
}
.content-name {
    max-width: 325px
}
.content-event-id {
    width: 10px;
    vertical-align: top
}
.content-event-name {
    width: 330px
}
.content-event-date {
    width: 200px
}
.content-actions {
    width: 20px
}
.content-actions img {
    display: block;
    margin: auto
}
.content .document-list tr,
.content .search-list tr {
    border-bottom: 5px solid #fff
}
.content .document-list td,
.content .search-list td {
    background-color: #f4f8fc
}
.content .sec-list td {
    background-color: #fde6d6
}
.cw-item--indent {
    display: block;
    margin-top: 5px;
    margin-left: 12px
}
.whats-new-datastore-list {
    padding-left: 25px
}
.sec-name {
    text-transform: uppercase
}
.content .category-list tr {
    border-top: 1px solid #d8e9f4
}
.page {
    padding: 35px
}
.BOP h1,
.SEC h1,
.LEG h1 {
    color: #2e60a5
}
.BIF h1,
.SRG h1,
.SOU h1 {
    color: #390
}
.MBP h1 {
    color: #2e60a5
}
.LAP h1,
.ISG h1,
.SYN h1,
.EVENT h1 {
    color: #8652a1
}
.BOP h2 {
    color: #2e60a5
}
.BIF h2 {
    color: #390
}
.LAP h2,
.MBP h2 {
    color: #2e60a5
}
.ISG h2,
.SYN h2 {
    color: #8652a1
}
.LEG h2 {
    color: #390
}
.SOU h2 {
    color: #8652a1
}
.EVENT h2 {
    color: #2e60a5
}
.breadcrumbs {
    margin: -20px 0 25px;
    color: #009483
}
.breadcrumbs a:link,
.breadcrumbs a:visited {
    text-decoration: none
}
.breadcrumbs a:hover {
    text-decoration: underline
}
.breadcrumbs span {
    font-weight: 300
}
.breadcrumbs .crumb1 {
    font-weight: 700
}
.breadcrumbs .crumb2 {
    font-weight: 400
}
.breadcrumbs .arrow {
    display: inline-block;
    font-size: .8em;
    color: #008575!important
}
.breadcrumbs .previous-link a:hover,
.breadcrumbs .previous-link a:link,
.breadcrumbs .previous-link a:visited {
    color: #d4145a;
    font-weight: 400
}
.store-options {
    margin-bottom: 20px
}
.store-options a {
    float: left;
    display: block;
    padding: 2px 10px;
    margin: 0 6px 0 0;
    font-weight: 700;
    text-decoration: none;
    font-size: 1em;
    background-color: #008575;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: relative;
    behavior: url(/PIE.htc);
}
.store-options a:link,
.store-options a:visited {
    color: #fff
}
.store-options a:focus {
    outline: 1px solid;
    outline-color: #025148;
}
.search-pagination {
    margin: 20px 0 0
}
.search-pagination .arrow {
    display: inline-block;
    color: #008575!important
}
.search-pagination .numbers {
    display: inline-block;
    margin: 0 10px;
    font-weight: 700
}
.search-pagination .numbers .active {
    text-decoration: underline;
    color: #000!important
}
.index {
    padding: 33px 0 35px 35px
}
.index h1 {
    width: 520px;
    margin: 0 0 20px;
    font-size: 1.935em;
    font-family: myriad-pro-n9, myriad-pro, sans-serif;
    font-weight: 900;
    font-style: normal;
    line-height: 1.2em;
    color: #000
}
.index .widget {
    float: left
}
.index .widget-left {
    width: 264px;
    margin: 0px 20px 0px 0;
}
.index .widget-right {
    width: 333px;
    margin: 0 0 50px 20px
}
.index .cobra-features {
    margin: 5px 0 0;
    color: #1A3866
}
.index .cobra-features .item {
    display: block;
    width: 330px;
    height: 26px;
    padding: 15px 0
}
.index .cobra-features .hairline {
    border-bottom: 1px solid #a1aec1;
    display: flex;
    align-items: center;
}
.index-logo {
    display: block;
    margin: 100px auto auto
}
.index .cobra-features i,
.index .cobra-features em {
    display: block;
    float: left;
    width: 29px;
    height: 29px;
    margin: 0 12px 0 0
}
.index .cobra-features .icon-clock {
    background: url(/images/default/icon-clock.png) top right no-repeat
}
.index .cobra-features .icon-clock-red {
    background: url(/images/default/icon-clock-red.png) top right no-repeat
}
.index .cobra-features .icon-files {
    background: url(/images/default/icon-files.png) top right no-repeat
}
.index .cobra-features .icon-graph {
    background: url(/images/default/icon-graph.png) top right no-repeat
}
.index .cobra-features .icon-scales {
    background: url(/images/default/icon-scales.png) top right no-repeat
}
.index .cobra-features .icon-map {
    background: url(/images/default/icon-map.png) top right no-repeat
}
.index .cobra-features .feature {
    display: block;
    margin: 0;
    float: left;
    width: 289px;
    font-size: 1.2em
}
.index .cobra-features .first {
    font-size: 1.26em;
    font-family: myriad-pro-n7, myriad-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase
}
.index .cobra-features a:link,
.index .cobra-features a:visited {
    color: #1A3866;
    text-decoration: none
}
.index .cobra-features a:hover {
    color: #1A3866;
    font-weight: 700;
    text-decoration: underline
}


.coronavirus-factsheets h1 {
    color: #b1242c;
}

.panel {
    border: 1px solid #ddd
}
.panel-heading {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd
}
.panel-body {
    padding: 15px
}
.multi-select-search {
    position: relative;
    width: 264px
}
.multi-select-search .search-wrapper {
    position: absolute;
    display: block;
    height: 50px;
    width: 264px;
    background: url(/images/default/multipass-input-background.png) top right no-repeat
}
.multi-select-search .search-wrapper span {
    display: block;
    margin: 3px 0 0 20px;
    color: #fff;
    height: 20px;
    font-size: 15px;
    font-weight: 700
}
.multi-select-search input {
    width: 235px;
    margin: 6px 0 0 20px;
    color: #000;
    border: 0;
    padding: 1px
}
.multi-select-search .multipass {
    position: relative;
    z-index: 100;
    top: 50px;
    padding: 10px 10px 10px 20px;
    width: 230px;
    height: 295px;
    color: #000;
    font-size: 14px;
    border: 2px solid #009483;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    cursor: default
}
.multi-select-search span {
    display: block;
    cursor: default
}
.multi-select-search .multipass span:hover {
    background-color: #ebebeb
}
.multi-select-search .active {
    color: #fff;
    background-color: #082069!important
}
.multipass span {
    width: 9999px;
    display: block;
    white-space: nowrap;
    overflow: hidden
}
.document-view a:link,
.document-view a:visited {
    color: #2e60a5
}
.document-view .actions {
    float: right;
    margin-top: 8px;
    width: 135px;
    text-align: right
}
.document-view .actions a:link,
.document-view .actions a:visited {
    color: #d4145a!important
}
.document-view .actions img, .document-view .actions span {
    display: inline-block !important;
    margin: 0 5px 0 10px
}
.document-view h1,
.document-view h2,
.document-view h3,
.document-view h4 {
    color: #000
}
.document-view h1 {
    font-size: 2em;
    line-height: 1.1em;
    margin: 0 0 5px
}
.document-view h2 {
    margin: 30px 0 15px
}
.document-view .SEC h2 {
    margin: 35px 0 15px;
    /*font-family: myriad-pro-condensed-n7, myriad-pro-condensed;*/
    font-family: myriad-pro-n7, myriad-pro;
    font-weight: 700;
    font-style: normal;
    font-size: 1.3em
}

.SEC > .subtitle {
    color: black;
    font-size: 16pt;
    font-weight: 500;
}

.SEC > .cw-meta {
    margin: 0 0 3px;
}

.document-view h3 {
    font-size: 1.06em;
    margin: 10px 0;
}
.document-view h4 {
    font-size: 0.96em;
    margin: 10px 0;
    font-style: italic;
}
.document-view a.internal:link,
.document-view a.internal:visited {
    color: #d4145a
}
.cw-datastore,
.cw-meta {
    color: #495050;
}
.cw-datastore {
    margin-bottom: 0!important
}
.document-view li,
.document-view p,
.document-view td {
    font-size: 1.05em
}
.document-view td {
    line-height: 1.28em
}
.document-view td * {
    font-size: 1em
}
.document-view th {
    padding: 10px 0 0;
    font-size: .9em;
    width: 75px;
    line-height: 1.7em;
    vertical-align: top;
    color: #2e60a5
}
.document-view tr {
    border-top: 1px solid #d8e9f4
}
.document-view td p {
    line-height: 1.3em
}
.document-view td p:last-child {
    margin: 0
}
.document-view .BOP ul li {
    list-style-image: url(/images/default/BOP-icon.png)
}
.document-view .BIF ul li {
    list-style-image: url(/images/default/BIF-icon.png)
}
.document-view .SYN ul li {
    list-style-image: url(/images/default/SYN-icon.png)
}
.document-view .ISG ul li {
    list-style-image: url(/images/default/ISG-icon.png)
}
.document-view .MBP ul li {
    list-style-image: url(/images/default/MBP-icon.png)
}
.document-view .LAP ul li {
    list-style-image: url(/images/default/LAP-icon.png)
}
.document-view .LEG ul li {
    list-style-image: url(/images/default/LEG-icon.png)
}
.document-view .SOU ul li {
    list-style-image: url(/images/default/SOU-icon.png)
}
.document-view .EVENT ul li {
    list-style-image: url(/images/default/EVENT-icon.png)
}
.document-view li ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    text-indent: -1em
}
.document-view li ul li:first-child {
    margin-top: 10px
}
.document-view li ul li {
    list-style: none!important
}
.document-view li ul li:before {
    content: "–";
    padding-right: 7px
}
.disclaimer {
    margin-top: 30px
}
.disclaimer p {
    font-size: 12px!important
}
.EVENT .cw-datastore,
.EVENT table {
    margin: 0 0 20px!important
}
.footer {
    padding: 12px 0;
    color: #fff;
    background-color: #1a3866
}
.footer a:link,
.footer a:visited {
    color: #fff;
}
.footer a:hover {
    color: #fff;
    text-decoration: none;
}
.reset-link:hover {
    text-decoration: none!important;
    color: #000!important
}
#print-logos {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block
}
#print-logos li {
    position: relative
}
#print-logos ul {
    display: none;
    position: absolute;
    width: 195px;
    padding-top: 8px;
    padding-left: 0;
    z-index: 999
}
#print-logos ul li {
    background: #f4f4f4;
    text-align: left;
    margin: 0
}
#print-logos ul li:first-child {
    border-bottom: #d7d7d7 1px solid
}
#print-logos ul li a {
    padding: 8px 8px 8px 39px;
    display: block
}
#print-logos ul li a i {
    padding-right: 11px
}
.document-view #print-logos li:before {
    display: none;
    width: 0;
    padding: 0;
    margin: 0
}
#print-logos ul img {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0
}

.doc-sprite {
    background-image: url('/images/default/doc-icons.png');
    background-repeat: no-repeat;
    display: block;
}

.sprite-icon_doc {
    width: 16px;
    height: 16px;
    background-position: -5px -5px;
}
.search-bar .search-fields {
    position:relative;
}
.search-bar .search-fields:before {
    content:"";
    background-image: url('/images/default/doc-icons.png');
    background-repeat: no-repeat;
    display: block;
    width: 9px;
    height: 13px;
    background-position: -57px -5px;
    position:absolute;
    top:7px;
    left:6px;
    z-index:999;
}

.sprite-icon_pdf {
    width: 16px;
    height: 16px;
    background-position: -31px -5px;
}

.sprite-icon_print {
    width: 16px;
    height: 16px;
    background-position: -5px -31px;
}

.sprite-mag {
    width: 9px;
    height: 13px;
    background-position: -57px -5px;
}
.content .ui-accordion {
    padding:5px 0px;
}
.content .ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    padding: 5px 25px;
    background: #dbe9f6;
    border: none;
    border-radius: initial;
    font-size: 14px;
    margin-top: 9px;
    margin-bottom: 0;
    border-top: 1px solid #CCC;
    border-left: 2px solid RGB(37,68,112);
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    box-shadow: inset 0px 0 0px 1px #fff;
    color:#BE1824;
}
.content .cw-content-accordion #trading-issues .wrap {
    padding-left:20px;
}

.content .BIF .ui-accordion .ui-accordion-header {
    border-left: 2px solid #339900;
}
.content .SYN .ui-accordion .ui-accordion-header {
    border-left: 2px solid #8652A1;
}
.content .ui-accordion .ui-accordion-content {
    border:0;
    padding:1em 0em;
    font-family: myriad-pro-n4,myriad-pro,verdana,sans-serif;
}
.content figure img {
    max-width: 100%;
}
.cw-content .ui-widget-content {
    color:#000;
}
.cw-content.ui-widget {
    font-size: 1.05em;
    color:#000;
    font-family: inherit;
}
#trading-issues > a {
    display:block;
}
#trading-issues > a :hover {
    text-decoration: underline !important;
    color: midnightblue !important;
}
#trading-issues > a > h3 {
    cursor: pointer;
    font-weight: normal;
    list-style-type: ethiopic-halehame-gez;
    /*border-top: 1px solid #EFEFEF;*/
    font-size: 13px;
    margin-left:20px;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 4px;
}
#trading-issues > a > span {
    font-size: 11px;
}
#trading-issues > a > h3.current{
    border-top:0;
    padding-top:0px;
}
#trading-issues > a > h3 a {
    font-size:11px;
    /*color: #2E60A5;*/
    padding-left:10px;
    /*text-decoration: none;*/
    font-weight: normal;
}
#trading-issues > a > h3 a.tradingToggleUp {
    display: inline-block;
    display: none;
    color: grey;
}
#trading-issues > a > span > img.bop-toggle-icon {
    margin-left:5px;
    margin-bottom: -2px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(90deg);
}

.view-more-arrow {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    width: 15px;
}

.standout-box {
    padding: 15px;
    margin-bottom: 31px;
    background: #f0f0f0;
    padding-bottom: 1px;
    font-size: 14px;
}

.standout-box p {
    line-height: 23px;
}

.standout-box i {
    color: #D73526;
    font-style: normal;
}

.content .augmented-category-view h1,
.content .augmented-category-view h2,
.content .augmented-category-view  h4 {
    color: #232323;
}

.content .augmented-category-view h4 {
    font-weight:normal;
}

.content .augmented-category-view .document-list td {
    background-color: #fff;
}

.content .augmented-category-view table td {
    padding: 13px 8px;
}

.augmented-category-view hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E8E8E8;
    margin: 1em 0;
    padding: 0;
}

.droppable {
    display: none;
 }

li.toggleable a {
    color: red;
}

li.toggled {
    list-style-type: none;
    border-top: 1px solid #add8e6;
    height: 20px;
    padding-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

li.togglable {
    list-style-type: none;
    border-top: 1px solid #add8e6;
    height: 20px;
    padding-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}
li.document-li {
    list-style-type: none;
    border-top: 1px solid #add8e6;
    height: 20px;
    padding-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

ul.category-list {
    padding: 0px;
}

.togglable:hover {
    background-color: #dbe9f6;
}

.loading::after {
    /*content: url("https://m.popkey.co/163fce/Llgbv_s-200x150.gif");*/
    position: absolute;
    -webkit-transform: scale(0.25) translateY(-270px) translateX(-290px);
    -moz-transform: scale(0.25) translateY(-270px) translateX(-290px);
    transform: scale(0.25) translateY(-270px) translateX(-290px);
}

.RSP span {
    color: darkgrey;
}

li.error {
    background-color: lightpink;
    border: 1px solid red;
}

#trading-dropdown.active {
    transform: rotate(90deg);
}

#trading-issues > .wrap {
    margin-left: 20px;
}

h3.indented {
    margin-left: 25px;
    color: #ff0000;
}

h3.indented > a :hover {
    color: #d4145a !important;
    text-decoration: underline !important;
}

#trading-content {
    font-size: 11px;
    padding-left: 10px;
    font-weight: 400;
}

#trading-content :hover {
    color: #d4145a !important;
    text-decoration: underline !important;
    cursor: pointer;
}
#doc-preview {
    border:0;
}
#doc-preview .content {
    padding: 50px;
    margin: 0 auto;
    float: none;
}
#doc-preview .main .arrow-up{
    font-size:11px;
}
