.cw-table {
    margin-bottom:15px;

    ul {
        padding-left: 15px;
        margin-top:0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    td {
        padding:8px;
        vertical-align: top;
    }

    tr {
        border-top: inherit;
    }
}

.document-view .cw-table ul li {
    list-style-image:none !important;
    margin: 0 0 5px;
}
